import React, { useEffect } from 'react';
import { ExportMainEntityEnum } from '@ternala/voltore-types/lib/constants';
import { EnterpriseGetListFilters } from '@ternala/voltore-types';

import { useDispatch, useSelector } from 'react-redux';
import { getExportTemplatesAction } from 'controllers/export/actions';
import { PersonsGetListFiltersExtended } from 'controllers/person/models';
import { IStore } from 'controllers/store';
import { PropertyGetListFiltersExtended } from 'controllers/property/models';

import { LOADED_ITEMS_LIMIT } from 'config';

import style from './ExportTab.module.scss';
import { LoaderAction } from 'config/constants';
import Loader from '../../../Loader';
import { addShowedElement } from 'controllers/showElement/actions';
import { ShowElementEnum } from 'controllers/showElement/models.d';
import { exportTemplateFilesModalKey } from "components/modals/ExportTemplateFilesContent";
import { TemplateIcon } from "components/icons/TemplateIcon";
import { uuid } from "utils";
import { downloadPersonExportAction } from 'controllers/person/actions';

interface IProps {
  entityType: ExportMainEntityEnum;
  getFilters: () => {
    persons?: PersonsGetListFiltersExtended;
    enterprises?: EnterpriseGetListFilters;
    property?: PropertyGetListFiltersExtended;
  };
}

const ExportTab: React.FC<IProps> = ({ entityType, getFilters }) => {
  const dispatch = useDispatch();
  const exportTemplates = useSelector((store: IStore) =>
    store.export.templates.map((id) => store.export.templateData[id]),
  );

  const storedSearchParams = useSelector((store: IStore) =>
    store.export.storedSearchParams,
  );

  const loaders = useSelector((store: IStore) =>
    store.export.state.loaders.filter(loader => loader.type === LoaderAction.export.getList),
  );

  useEffect(() => {
    if (exportTemplates?.length && entityType === storedSearchParams?.entityType) return;
    dispatch(
      getExportTemplatesAction.request({
        offset: 0,
        limit: LOADED_ITEMS_LIMIT,
        entityType,
      }),
    );
  }, [entityType]);

  // const handleClick = (id: number) => {
  //   getAccessTokenUtil(dispatch).then((token) => {
  //     ExportApi.downloadFile(id, getFilters() || {}, entityType, token).then(
  //       (res) => {
  //         console.log('res: ', res);
  //       },
  //     );
  //   });
  // };

  const handleClick = (id: number) => {
    dispatch(addShowedElement({
      type: ShowElementEnum.modal,
      key: exportTemplateFilesModalKey,
      props: {
        id,
        entityType,
        filters: getFilters()
      },
      id: uuid()
    }))
  }

  const handleDownload = () => {
    console.log('handleDownload');
    dispatch(downloadPersonExportAction.request({}))
  }

  return (
    <div className={style.tab + " tab"}>
      {loaders.length ? <Loader /> : ""}
      <ul className={style.list}>
        {exportTemplates.map((template) => (
          <li key={template.id} className={style.list__item} onClick={() => handleClick(template.id)}>
            <TemplateIcon />
            <span>{template.title}</span>
          </li>
        ))}
      </ul>
      <h5 className={style.list_title}>Quick exports: </h5>
      <ul className={style.list}>
        <li className={style.list__item} onClick={() => {
          console.log('click');
          handleDownload()
        }}>
          <TemplateIcon />
          <span>People without Properties</span>
        </li>
      </ul>
    </div>
  );
};

export default ExportTab;
