import React, { RefObject, useState, Fragment, ChangeEvent } from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import { ItemsLoadType, OptionType } from 'models';
import Scrollbars from 'react-custom-scrollbars';
import { CardDTO, CardFullDTO } from '@ternala/voltore-types/';

import Card from './Controls/Card';

import SelectSort from 'components/UI/controls/SelectSort';
import Loader from 'components/Loader';
import { CardFilterEnum } from 'controllers/card/models.d';

/* components */
import Input from 'components/UI/controls/Input';

/* icons */
import SearchIcon from 'components/icons/Notes/SearchIcon';
import ScrollWrapper from './ScrollWrapper';
import { ReminderDTO } from '@ternala/voltore-types';

interface IProps {
  modal: CardFilterEnum;
  cards?: (CardDTO | CardFullDTO)[];
  loading: boolean;
  allItemsLoaded?: boolean;
  isAllCards: boolean;

  editedCard: CardDTO | null;
  setEditedCard: (card: CardDTO | null) => void;

  onLoadMore: (loadType: ItemsLoadType, callback?: Function) => void;
  onSort: (
    sortOption: OptionType,
    type: 'Sequence' | 'State' | 'Entity' | 'Identity' | 'Deleted',
  ) => void;

  elementRef: RefObject<Scrollbars>;

  sortSequenceValue?: OptionType;
  sortStateValue?: OptionType;
  sortEntityValue?: OptionType;
  sortIdentityValue?: OptionType;
  deletedOption?: OptionType;

  deletedOptions: OptionType[];
  sortOptions: OptionType[];
  entitySortOptions: OptionType[];
  stateSortOptions: OptionType[];
  identitySortOptions: OptionType[];

  searchPlaceholder: string;
  Filters?: any;
  count: number;

  searchQuery: string;
  onSearch: (event: ChangeEvent<HTMLInputElement>) => void;
  clearSearchQuery: Function;
}

let timeout;

const DataList = (props: IProps) => {
  const {
    editedCard,
    setEditedCard,
    modal,
    cards,
    loading,
    allItemsLoaded,
    onLoadMore,
    isAllCards,
    onSort,

    deletedOptions,
    deletedOption,

    sortOptions,
    entitySortOptions,
    sortStateValue,
    stateSortOptions,
    identitySortOptions,
    sortSequenceValue,
    sortEntityValue,
    sortIdentityValue,
    searchPlaceholder,
    searchQuery,
    onSearch,
    clearSearchQuery,
  } = props;
  // Use state
  const [search, setSearch] = useState<boolean>(false);

  return (
    <div className={'datalist'}>
      <div className={`datalist-search ${search ? 'input' : ''}`}>
        {search ? (
          <Input
            className="datalist-search-area"
            placeholder={searchPlaceholder}
            value={searchQuery}
            onChange={onSearch}
            callback={() => clearSearchQuery()}
            transparentIcon
            onClose={() => setSearch(false)}
            search
          />
        ) : (
          <div className={'datalist-filters'}>
            <div className="sort-option">
              <SelectSort
                options={sortOptions}
                value={sortSequenceValue}
                onChange={(value) => onSort(value, 'Sequence')}
                disabled={cards && cards.length === 0}
              />
              <SelectSort
                options={entitySortOptions}
                value={sortEntityValue}
                withOverlap={modal === CardFilterEnum.reminder}
                onChange={(value) => onSort(value, 'Entity')}
                style={{
                  marginRight: '18px',
                }}
              />
              {modal === CardFilterEnum.note ? (
                <SelectSort
                  options={deletedOptions}
                  value={deletedOption}
                  onChange={(value) => onSort(value, 'Deleted')}
                  style={{
                    marginRight: '18px',
                  }}
                />
              ) : ''}
                {modal === CardFilterEnum.reminder ? (
                <>
                  <SelectSort
                    options={stateSortOptions}
                    value={sortStateValue}
                    onChange={(value) => onSort(value, 'State')}
                    style={{ marginRight: '18px' }}
                  />
                  <SelectSort
                    options={identitySortOptions}
                    value={sortIdentityValue}
                    onChange={(value) => onSort(value, 'Identity')}
                  />
                </>
              ) : (
                ''
              )}
            </div>
            <div className={'header-search'} onClick={() => setSearch(true)}>
              <SearchIcon />
            </div>
          </div>
        )}
      </div>
      <div className="datalist-items">
        {!loading ? (
          <ScrollWrapper
            onLoadMore={onLoadMore}
            isCanLoad={!allItemsLoaded && !loading && !isAllCards}>
            {cards && cards?.length > 0 ? (
              <>
                {modal === CardFilterEnum.note &&
                  cards?.map((card, i, arr) => {
                    const previousItem = arr[i - 1];
                    if (
                      !card?.hasOwnProperty('cardTagConnect') &&
                      !card?.hasOwnProperty('note')
                    )
                      return '';
                    return (
                      <Fragment
                        key={
                          String(card.id) +
                          (card.reminders?.find(
                            (reminder: ReminderDTO & { isMain?: boolean }) =>
                              reminder.isMain,
                          )?.id || 0)
                        }>
                        {i > 0 &&
                        moment(card.createdAt).isSame(
                          previousItem.createdAt,
                          'day',
                        ) ? (
                          ''
                        ) : (
                          <div className={'date-added'}>
                            {moment().isSame(card.createdAt, 'day') ? (
                              <span className={'date'}>
                                <span className={'today'}>Today </span>-{' '}
                                <Moment format={'dddd, LL'}>
                                  {card.createdAt}
                                </Moment>
                              </span>
                            ) : (
                              <span className={'date'}>
                                <Moment format={'dddd, LL'}>
                                  {card.createdAt}
                                </Moment>
                              </span>
                            )}
                          </div>
                        )}
                        <Card
                          card={card}
                          modal={modal}
                          setEditedCard={setEditedCard}
                          editedCard={editedCard}
                        />
                      </Fragment>
                    );
                  })}
                {modal === CardFilterEnum.reminder &&
                  cards?.map((card, i, arr) => {
                    const reminder = card.reminders?.find(
                      (reminder: ReminderDTO & { isMain?: boolean }) =>
                        reminder.isMain,
                    );
                    const key = String(card.id) + String(reminder?.id || 0);
                    const previousItem = arr[i - 1]?.reminders?.find(
                      (reminder: ReminderDTO & { isMain?: boolean }) =>
                        reminder.isMain,
                    );
                    return (
                      <Fragment key={key}>
                        {i > 0 &&
                        moment(reminder?.date).isSame(
                          previousItem?.date,
                          'day',
                        ) ? (
                          ''
                        ) : (
                          <div className={'date-added'}>
                            {moment().isSame(reminder?.date, 'day') ? (
                              <span className={'date'}>
                                <span className={'today'}>Today </span>-{' '}
                                <Moment format={'dddd, LL'}>
                                  {reminder?.date}
                                </Moment>
                              </span>
                            ) : (
                              <span className={'date'}>
                                <Moment format={'dddd, LL'}>
                                  {reminder?.date}
                                </Moment>
                              </span>
                            )}
                          </div>
                        )}
                        <Card
                          card={card}
                          modal={modal}
                          editedCard={editedCard}
                          setEditedCard={setEditedCard}
                        />
                      </Fragment>
                    );
                  })}
              </>
            ) : isAllCards ? (
              <>
                {modal === CardFilterEnum.note ? (
                  <div className="items-not-found">no notes to display</div>
                ) : (
                  <div className="items-not-found">no reminders to display</div>
                )}
              </>
            ) : (
              <></>
            )}
          </ScrollWrapper>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default DataList;
