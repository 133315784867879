import React, {
  RefObject,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { CardGetListRequest, ReminderDTO } from '@ternala/voltore-types';
import { UserDTO } from '@ternala/voltore-types/lib/modules/user/user.dto';
import {
  cardSortFields,
  EntityEnum,
  sortType,
} from '@ternala/voltore-types/lib/constants';
import { CardDTO } from '@ternala/voltore-types/lib/modules/card/card.dto';

// components
import NoteCreator from '../NoteCreator';
import DataList from '../DataList';

// icons
import { CloseDarkIcon } from 'components/icons/Notes/CloseDarkIcon';

// config
import { LOADED_ITEMS_LIMIT } from 'config';
import {
  cardsSortOptions,
  deletedOptions,
  Sorting,
} from 'config/constants/sorting';

// controllers
import { getCardsAction } from 'controllers/card/actions';
import { IStore } from 'controllers/store';

// types
import { ItemsLoadType, OptionType, sortingTypeKeys } from 'models';
import { CardFilterEnum, ICardState } from 'controllers/card/models.d';
import PreviewContext from 'context/Modal';
import { CountActionsProvider } from '../Contexts';

// components
import Trail from '../../Trail';

// utils
import {
  generateOptions,
  identitySortOptions,
  stateSortOptions,
} from '../utils';
import { sortCard } from 'utils/sortCard';
import { ISelectEntityData } from 'controllers/showElement/models';

// Props interface
interface IProps {
  // redux
  selectedEntity?: ISelectEntityData;
  cardState: ICardState;
  account?: UserDTO;

  modal: CardFilterEnum;

  Filters?: any;
  onClose: () => void;
}

const NotesFunctionalModal = ({
  modal,
  onClose,
  selectedEntity,
  cardState: {
    isAll,
    cardData,
    count,
    reminderData,
    cards,
    reminders,
    remindersCount,
  },
  Filters,
  account,
}: IProps) => {
  const timeout = useRef<number>();
  const [isRunning, setIsRunning] = useState<boolean>(true);

  // general context (filters, modals)
  const previewContext = useContext(PreviewContext);
  const [countActions, setCountActions] = useState<number | undefined>(
    undefined,
  );
  // sort option
  const entitySortOptions = generateOptions(selectedEntity);
  // Use state
  const [searchQuery, setSearchQuery] = useState<string>('');

  const [sortSequenceValue, setSortSequenceValue] = useState<OptionType>(
    modal === CardFilterEnum.reminder
      ? cardsSortOptions[0]
      : cardsSortOptions[1],
  );
  const [deletedValue, setDeletedValue] = useState<OptionType>(
    deletedOptions[0],
  );
  const [sortStateValue, setSortStateValue] = useState<OptionType>(
    stateSortOptions[1],
  );
  const [sortEntityValue, setSortEntityValue] = useState<OptionType>(
    entitySortOptions[2] ? entitySortOptions[2] : entitySortOptions[0],
  );
  const [sortIdentityValue, setSortIdentityValue] = useState<OptionType>(
    modal === CardFilterEnum.reminder
      ? account?.roles.length
        ? identitySortOptions[2]
        : identitySortOptions[2]
      : identitySortOptions[1],
  );

  const [loading, setLoading] = useState<boolean>(true);

  const [editedCard, setEditedCard] = useState<CardDTO | null>(null);

  // Sort functionality
  const sorting = Sorting[sortSequenceValue.value as sortingTypeKeys];

  // React-trail functionality
  const [trail, setTrail] = useState<boolean>(true);

  useEffect(() => {
    if (modal) {
      !trail && setTrail(true);
    } else {
      trail && setTrail(false);
    }
  }, [modal]);

  // Getting full list of history cards
  const dispatch = useDispatch();

  // Scroll to the bottom functionality
  const elementRef = useRef() as RefObject<any>;

  const loadMore = () => {
    loadCards('start', () => setLoading(false));
  };

  useEffect(() => {
    setLoading(true);
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(function () {
      loadMore();
    }, 700);
  }, [
    searchQuery
  ]);

  useEffect(() => {
    setLoading(true);
    clearTimeout(timeout.current);
    timeout.current = window.setTimeout(function () {
      loadMore();
    }, 200);
  }, [
    sortSequenceValue,
    sortEntityValue,
    sortStateValue,
    deletedValue,
    sortIdentityValue,
  ]);

  useEffect(() => {
    const purposeValue = entitySortOptions[2]
      ? entitySortOptions[2]
      : entitySortOptions[0];
    if (sortEntityValue.value !== purposeValue.value) {
      setSortEntityValue(purposeValue);
    }
    setLoading(true);
    loadMore();
  }, [selectedEntity]);

  // Load more cards
  const loadCards = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams: CardGetListRequest = {
      limit: LOADED_ITEMS_LIMIT,
      offset:
        loadType === 'more'
          ? modal === CardFilterEnum.reminder
            ? reminders?.length
            : cards?.length
          : 0,
      query: searchQuery,
      sortType: sorting.sortType,
      sortField: sorting.sortField as cardSortFields,
    };

    const payload = {
      ...searchParams,
      ...(sortIdentityValue.value === 3 ? { author: account?.id } : {}),
      ...(sortIdentityValue.value === 1 && modal === CardFilterEnum.reminder
        ? { reminderRoles: account?.roles.map((role) => role.id) }
        : {}),
      ...(modal === CardFilterEnum.reminder && {
        isComplete: sortStateValue.value === 1,
      }),
      ...(typeof deletedValue.value === 'boolean' && {
        isDeleted: deletedValue.value,
      }),
      ...(modal === CardFilterEnum.reminder ? { onlyReminder: true } : {}),
      callback: callback,
      type: modal,
    };

    // Functionality for entity
    if (selectedEntity?.ids) {
      const { ids, type } = selectedEntity;
      if (
        sortEntityValue.value !== 1 &&
        type &&
        [
          EntityEnum.property,
          EntityEnum.enterprise,
          EntityEnum.person,
        ].includes(type)
      ) {
        payload[
          type as
            | EntityEnum.property
            | EntityEnum.enterprise
            | EntityEnum.person
        ] = ids[0];
      }
      if (sortEntityValue.value === 3) {
        payload.includeOwnedProperties = true;
      }
    }
    dispatch(getCardsAction.request({ ...payload }));
  };

  // On sort elements
  const onSort = (
    sortOption: OptionType,
    type: 'Sequence' | 'State' | 'Entity' | 'Identity' | 'Deleted',
  ) => {
    switch (type) {
      case 'Sequence':
        return setSortSequenceValue(sortOption);
      case 'State':
        return setSortStateValue(sortOption);
      case 'Entity':
        return setSortEntityValue(sortOption);
      case 'Identity':
        return setSortIdentityValue(sortOption);
      case 'Deleted':
        return setDeletedValue(sortOption);
    }
  };

  // Getting cards
  const cardArray = cards?.map((id) => cardData[id]);
  const reminderArray = reminders?.map((card) => reminderData[card]);

  // Sort cards by date
  const sortedCards = sortCard({
    cards: cardArray,
    type: sorting.sortType,
    field: sorting.sortField,
  });

  const sortedReminders = useMemo<CardDTO[] | undefined>(
    () =>
      reminderArray?.sort((card1, card2) => {
        const reminder1 = card1.reminders?.find(
          (reminder) => (reminder as ReminderDTO & { isMain: boolean }).isMain,
        )?.date;
        const reminder2 = card2.reminders?.find(
          (reminder) => (reminder as ReminderDTO & { isMain: boolean }).isMain,
        )?.date;

        return (
          ((reminder1 ? new Date(reminder1).getTime() : 0) -
            (reminder2 ? new Date(reminder2).getTime() : 0)) *
          (sorting.sortType === sortType.asc ? 1 : -1)
        );
      }),
    [reminderArray, sorting],
  );

  const noteProps: { [key: string]: number[] } = {};
  if (selectedEntity?.type && selectedEntity.ids) {
    noteProps[selectedEntity.type] = selectedEntity.ids;
  }

  useEffect(() => {
    setTimeout(() => {
      setIsRunning(false);
    }, 4000);
  }, []);

  return (
    <div
      className={`window ${
        previewContext.preview?.modal === modal ? 'used' : ''
      } ${isRunning ? ' running' : ''}`}>
      <Trail open={trail}>
        <div className={'window-content'}>
          <div className="window-content-top">
            <div className={'header'}>
              <div className={'header-title'}>
                {modal === CardFilterEnum.note ? 'notes' : 'reminders'}
              </div>
              <div className={'header-close'} onClick={onClose}>
                <CloseDarkIcon />
              </div>
            </div>
            <DataList
              modal={modal}
              count={
                modal === CardFilterEnum.reminder ? remindersCount : count || 0
              }
              cards={
                modal === CardFilterEnum.note ? sortedCards : sortedReminders
              }
              searchPlaceholder={
                modal === CardFilterEnum.note
                  ? 'Global search by note content, mentioned entity'
                  : 'Global search by reminder content, user (person) name, role'
              }
              searchQuery={searchQuery}
              onSearch={(e) => setSearchQuery(e.target.value)}
              clearSearchQuery={() => setSearchQuery('')}
              Filters={Filters}
              editedCard={editedCard}
              setEditedCard={setEditedCard}
              elementRef={elementRef}
              loading={loading}
              isAllCards={!!isAll}
              allItemsLoaded={isAll}
              onLoadMore={loadCards}
              onSort={onSort}
              sortSequenceValue={sortSequenceValue}
              sortStateValue={sortStateValue}
              sortEntityValue={sortEntityValue}
              sortIdentityValue={sortIdentityValue}
              deletedOptions={deletedOptions}
              deletedOption={deletedValue}
              sortOptions={cardsSortOptions}
              entitySortOptions={entitySortOptions}
              stateSortOptions={stateSortOptions}
              identitySortOptions={identitySortOptions.map((option) => ({
                ...option,
                isDisabled: !account?.roles.length && option.value === 1,
              }))}
            />
          </div>
          <CountActionsProvider
            value={{
              countActions: countActions,
              setCount: (count) => {
                setCountActions(count);
              },
            }}>
            <NoteCreator
              {...noteProps}
              modal={modal}
              editedCard={editedCard}
              clearEditedCard={() => setEditedCard(null)}
            />
          </CountActionsProvider>
        </div>
      </Trail>
    </div>
  );
};

export default connect((store: IStore) => ({
  account: store.auth.account,
  cardState: store.card,
  selectedEntity: store.showElement.selectedEntity,
}))(NotesFunctionalModal);
